import React, { useState } from "react";
import styled from "styled-components";
import { Formik } from "formik";
import * as yup from "yup";
import Recaptcha from "react-recaptcha";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import { contact } from "../utils/contactContent.json";
import variable from "./variable";

const Contact = () => {
    const [captcha, setCaptcha] = useState(false);
    const [inputFile, setInputFile] = useState(false);

    const override = css`
        display: block;
        margin: 0 auto;
    `;

    if (typeof document !== "undefined") {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }

    const handleErrors = response => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    };
    const FILE_SIZE = 10000 * 1024;
    const SUPPORTED_FORMATS = [
        "application/pdf",
        "application/zip",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    return (
        <ContactContainer>
            <Adresse>
                <div className="title-container mt0">
                    <h2 className="title-before">{contact.titre1}</h2>
                </div>
                <h4 className="secondary-title mt0 mb0">{contact.soustitre} </h4>
                <p className="addresse">
                    <strong>{contact.adresse}</strong>
                </p>
                <p>{contact.content1}</p>
                <p>
                    <strong>{contact.bold1}</strong> {contact.content2}
                </p>
                <p>
                    <strong>{contact.bold2}</strong> {contact.content3}
                </p>
            </Adresse>
            <Form>
                <div className="title-container mt0">
                    <h2 className="title-before">{contact.titre2}</h2>
                </div>

                <Formik
                    enctype="multipart/form-data"
                    initialValues={{
                        name: "",
                        email: "",
                        sujet: "",
                        message: "",
                        file: null,
                        recaptcha: "",
                    }}
                    onSubmit={(
                        { file, name, email, sujet, message, recaptcha },
                        { resetForm, setSubmitting, setStatus },
                    ) => {
                        const formData = new FormData();
                        formData.append("file", file);
                        formData.append("name", name);
                        formData.append("email", email);
                        formData.append("sujet", sujet);
                        formData.append("message", message);
                        formData.append("recaptcha", recaptcha);

                        fetch(`${process.env.SERVER_MAIL_HOST}/send-email`, {
                            method: "POST",
                            body: formData,
                        })
                            .then(handleErrors)
                            .then(() => {
                                inputFile.value = "";
                                resetForm();
                                captcha.reset();
                                setStatus({ success: true, error: false });
                                setSubmitting(false);
                            })
                            .catch(() => {
                                setStatus({ success: false, error: true });
                                setSubmitting(false);
                            });
                    }}
                    validationSchema={yup.object().shape({
                        name: yup.string().required("Un nom est requis"),
                        email: yup
                            .string()
                            .email("L'email est invalide")
                            .required("L'email est requis"),
                        sujet: yup.string().required("Un sujet est requis"),
                        message: yup.string(),

                        file: yup
                            .mixed()
                            .test("fileFormat", "Le format n'est pas supporté", value =>
                                value
                                    ? value.type.startsWith("image/") || SUPPORTED_FORMATS.includes(value.type)
                                    : true,
                            )
                            .test("fileSize", "Le fichier ne doit pas dépasser 10 Mo", value =>
                                value ? value.size <= FILE_SIZE : true,
                            ),
                        recaptcha: yup.string().required("Veuillez remplir le captcha"),
                    })}
                    render={({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        handleChange,
                        setFieldValue,
                        isSubmitting,
                        status,
                    }) => [
                        <SuccessMessage
                            key="success-message"
                            style={{ display: status && status.success ? "flex" : "none" }}
                        >
                            <p>Super ! Votre message a bien été envoyé</p>
                        </SuccessMessage>,
                        <ErrorMessage key="error-message" style={{ display: status && status.error ? "flex" : "none" }}>
                            <p>Mince, il y a eu un problème lors de l'envoi</p>
                        </ErrorMessage>,
                        <form key="form" onSubmit={handleSubmit} noValidate>
                            <div className="unit-field">
                                <label htmlFor="name">Nom :</label>
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    value={values.name}
                                    onChange={handleChange}
                                />
                                {errors.name && touched.name && <p className="error">{errors.name}</p>}
                            </div>
                            <div className="unit-field">
                                <label htmlFor="email">E-mail :</label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    className="form-control"
                                    value={values.email}
                                    onChange={handleChange}
                                />
                                {errors.email && touched.email && <p className="error">{errors.email}</p>}
                            </div>
                            <div className="unit-field">
                                <label htmlFor="sujet">Sujet :</label>
                                <input
                                    id="sujet"
                                    name="sujet"
                                    type="sujet"
                                    value={values.sujet}
                                    onChange={handleChange}
                                />
                                {errors.sujet && touched.sujet && <p className="error">{errors.sujet}</p>}
                            </div>
                            <div className="unit-field">
                                <label htmlFor="message">Message :</label>
                                <textarea
                                    rows="3"
                                    cols="33"
                                    id="message"
                                    name="message"
                                    type="message"
                                    value={values.message}
                                    onChange={handleChange}
                                />
                                {errors.message && touched.message && <p className="error">{errors.message}</p>}
                            </div>
                            <div className="file">
                                <input
                                    ref={e => setInputFile(e)}
                                    id="file"
                                    name="file"
                                    type="file"
                                    accept="image/*, application/pdf, application/zip, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    onChange={e => {
                                        setFieldValue("file", e.currentTarget.files[0]);
                                    }}
                                />
                                {errors.file && touched.file && <p className="error">{errors.file}</p>}
                            </div>
                            <div className="unit-field">
                                <div className="captcha">
                                    <Recaptcha
                                        sitekey={process.env.RECAPTCHA_SITE_KEY}
                                        render="explicit"
                                        ref={e => setCaptcha(e)}
                                        theme="light"
                                        hl="fr"
                                        verifyCallback={response => {
                                            setFieldValue("recaptcha", response);
                                        }}
                                        onloadCallback={() => {}}
                                    />
                                    {errors.recaptcha && touched.recaptcha && (
                                        <p className="error">{errors.recaptcha}</p>
                                    )}
                                </div>
                            </div>
                            <div className="button">
                                <button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? (
                                        <ClipLoader
                                            css={override}
                                            sizeUnit="px"
                                            size={20}
                                            color={variable.lightSecondary}
                                            loading={isSubmitting}
                                        />
                                    ) : (
                                        <React.Fragment>Envoyer</React.Fragment>
                                    )}
                                </button>
                            </div>
                        </form>,
                    ]}
                />
            </Form>
        </ContactContainer>
    );
};

const ContactContainer = styled.div`
    display: flex;
    justify-content space-between;
    @media screen and (max-width: 992px) {
        flex-direction: column;
    }
`;
const Adresse = styled.div`
    width: 35%;
    @media screen and (max-width: 992px) {
        width: 100%;
    }
    .addresse {
        color: ${variable.darkGrey};
        font-weight: 500;
    }
`;
const Form = styled.div`
    width: 55%;
    @media screen and (max-width: 992px) {
        width: 100%;
        margin-top: 20px;
    }
    .unit-field {
        margin-top: 10px;
        span {
            color: ${variable.secondGrey};
            text-align: right;
            font-size: 12px;
            font-weight: normal;
        }
    }
    label {
        color: ${variable.bodyColor};
        font-weight: 500;
    }
    input,
    textarea {
        border-radius: 12px;
        border: none;
        outline: none;
        flex: 1;
        padding: 10px;
        color: ${variable.bodyColor};
        line-height: 25px;
        width: 100%;

        background-color: ${variable.lightGrey};
    }
    input {
        height: 40px;
    }
    #file {
        padding: 0;
        background: none;
    }
    .fileCaptcha {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 992px) {
            flex-direction: column;
        }
    }
    .button {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        font-style: italic;
    }
    .captcha {
        margin-left: 10px;
        margin-top: 20px;
        width: 300px;
        @media screen and (max-width: 992px) {
            margin-top: 20px;
        }
    }
    .file {
        margin-top: 20px;
        margin-left: 10px;
        input {
            height: unset;
            border-radius: 0;
        }
    }
    .error {
        padding-left: 10px;
        margin: 0;
        color: ${variable.secondGrey};
    }
    .zone {
        flex: 1;
        margin-right: 10px;
    }

    button {
        color: ${variable.primaryColor};
        background-color: ${variable.secondaryColor};
        & :hover {
            border-color: ${variable.primaryColor};
        }
    }
`;

const SuccessMessage = styled.div`
transition:0,2s;
margin-top: 15px;
height:60px;
justify-content:center;
border-radius:5px;
align-items:center;
background rgba(47, 217, 183, 0.25);

p {
  margin:0;
  color:#2fd9b7;
}
`;
const ErrorMessage = styled.div`
    transition: 0, 2s;
    margin-top: 15px;
    height: 60px;
    justify-content: center;
    border-radius: 5px;
    align-items: center;
    background: rgba(215, 38, 61, 0.2);

    p {
        margin: 0;
        color: #d7263d;
    }
`;

export default Contact;

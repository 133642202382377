import React from "react";
import Contact from "../components/contactContent";

import Wrapper from "../components/wrapper";

import Layout from "../components/layout";

const Agile = () => (
    <Layout>
        <Wrapper>
            <h1>Contact</h1>
            <Contact />
        </Wrapper>
    </Layout>
);

export default Agile;
